@import "../variables.scss";
@import "../mixins/_mixins.scss";



.slider {
    position: relative;

    width: 100%;
    padding: 8px;

    overflow: hidden;

    .track {
        display: flex;
        align-items: center;

        text-align: center;

        width: 100%;

        cursor: grab;

        :active {
            cursor: grabbing;
        }

        img {
            flex-shrink: 0;

            width: 100%;
            aspect-ratio: 1 / 1;


            border-radius: 16px;
            background-color: $surface-dark;

            object-fit: cover;

            &:nth-of-type(2) { background-color: green; }
            &:nth-of-type(3) { background-color: yellow; }
        }
    }

    .dot_container {
        display: flex;
        justify-content: center;
        gap: 5px;

        width: 100%;
        margin-block: 16px;

        .dot {
            width: 8px;
            height: 8px;

            border-radius: 100%;
            border: none;

            cursor: pointer;

            &.active {
                background-color: $surface-on;
            }

            &.inActive {
                background-color: hsla(0, 0%, 100%, 0.5);
            }
        }
    }
}


