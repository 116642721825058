@use "sass:map";

@import "../mixins/_mixins.scss";
@import "../variables.scss";


.hero_container {
    padding-block: 64px;

    .overlap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 64px;


        max-width: 1400px;
        margin: 0 auto;

        .info_container {    
            display: flex;
            flex-direction: column;
            gap: 16px;

            align-self: center;

            h3 {
                font-size: map.get($fs, "80");
            }

            p {
                font-size: map.get($fs, "40");
            }
        }

        .button_container {
            display: flex;
            align-items: center;
            gap: 16px;

            margin-block: 32px;

            .line {
                width: 50px;
                height: 2px;

                background-color: $primary;
            }
        }
    }

    @include respondTo("x-small") {
        .overlap {
            grid-template-columns: none;
            grid-template-rows: repeat(2, auto);
            gap: 16px;

            .info_container {

                h3 { font-size: map.get($fs, "70");}
            }
        }
    }
}

.feature_container {
    padding-block: 64px;
    background-color: $surface-dark;

    > * {
        gap: 32px;
        // align-items: center;
    }

    p { 
        font-size: map.get($fs, "70");
    }

    @include respondTo("small") {
        padding-inline: 32px;

        p { 
            font-size: map.get($fs, "30");
        }
    }
}

.card_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    padding-block: 32px;

    @include respondTo("small") {
        grid-template-columns: none;
        grid-template-rows: repeat(auto-fit, 1fr);
    }
}