@use "sass:map";

@import "../variables.scss";
@import "../mixins/mixins";

.desktop_menu {

    ul {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 8px;


        align-items: center;
        list-style: none;

        li {
            display: block;

            a {
                display: block;

                padding: 8px;
                text-decoration: none;

                color: $surface-on;

                font-size: map.get($fs, "30");

                &.active {
                    color: $primary;
                }
            }
        }
    }
}

.mobile_menu {
    position: absolute;

    width: 100%;
    height: 100dvh; 
    
    background-color: $surface-light;

    z-index: 9999;

    ul {
        padding: 32px;
        list-style: none;

        li {
            width: 100%;
            
            text-align: center;

            a {
                display: block;
                padding-block: 16px;
                
                text-decoration: none;

                color: $surface-on;
                
                font-size: map.get($fs, "40");

                &.active {
                    color: $primary;
                }
            }
        }
    }
}





