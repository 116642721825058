@import "../mixins/_mixins.scss";
@import "../variables.scss";


.serviceCard {
    
    padding: 16px;
    
    background-color: $surface-dark;
    border-radius: 16px;

    .overlap {
        display: grid;
        gap: 32px;

        .img_container {
            border-radius: 8px;

            aspect-ratio: 1;
            
            img {
                object-fit: cover;
            }
        }

        .info_container {
            h4 {
                margin-bottom: 8px;
                font-size: map-get($fs, "50");
            }

            p {
                font-size: map-get($fs, "40");
                opacity: .5;
            }
        }
    }    

    @include respondTo("small") {
        .overlap {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "col1 col2";
            align-items: center;
           
            .img_container {
                grid-area: col1;
                aspect-ratio: 1;
                
                img { object-fit: cover; }
            }

            .info_container {
                grid-area: col2;
            }
        }
    }

    @include respondTo("x-small") {
        .overlap {
            grid-template-columns: none;
            grid-template-areas: "row1" "row2";

            .img_container {
                grid-area: row2;
                aspect-ratio: 1;
            }

            .info_container {
                grid-area: row1;
                
                h4 {
                    margin-bottom: 8px;
                    font-size: map-get($fs, "40");
                }

                p {
                    font-size: map-get($fs, "30");
                }
            }
        }
    }
}
