@import "../mixins/_mixins.scss";
@import "../variables.scss";

.button {
    padding: 10px 20px;
                
    font-size: map-get($fs, "30");
    color: $surface-on;
                                
    border: none;
    border-radius: 8px;
                
    cursor: pointer;

    &.primary {

        &.filled {

            background-color: $primary;
        }


        &.outlined {
            background: none;
            border: 2px solid $primary;
            color: $primary;
        }
    }
}
