@import "../variables.scss";
@import "../mixins/mixins";

.footer {
    padding-block: 30px;

    background-color: $surface-dark;

    .overlap {
        .contact_container {
            padding-block: 16px;
            border-bottom: 2px solid $surface-on;

            .info {
                display: grid;
                grid-template-columns: 25px 1fr;
                gap: 16px;
                align-items: center;

                padding-block: 4px;

                width: fit-content;

                img {
                    grid-column: 1 / 2;
                    width: 25px;
                }   

                a {
                    text-decoration: none;
                    grid-column: 2 / 3;

                    color: $surface-on;
                }

                &:hover {
                    svg { fill: $primary; }
                    a { color: $primary; }
                }
            }
        }

        .sm_container {
            display: grid;
            grid-template-columns: repeat(auto-fit, 40px);
            gap: 8px;
            padding-block: 8px;
            
            .info {

                gap: 16px;
                align-items: center;

                padding-block: 4px;

                width: fit-content;
                
   

                a {
                    text-decoration: none;
                    grid-column: 2 / 3;

                    color: $surface-on;
                }

                &:hover {
                    svg { fill: $primary; }
                    a { color: $primary; }
                }
            }
        }
    }
}