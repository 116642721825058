@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import "./variables.scss";
@import "./mixins/_mixins.scss";


*, html { margin: 0; padding: 0; box-sizing: border-box; }

body {    
    background-color: $surface;
    color: $surface-on;
 
    font-family: "Inter", sans-serif;
}

main {
    padding-block: 30px;
}

img {
    width: 100%;
    height: 100%;

    border-radius: inherit;
}
