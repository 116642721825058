$primary: #2270B7;

$secondary: #CBCBCB;

$surface: #191919;
$surface-light: #212121;
$surface-dark: #141414;

$surface-on: #eeeeee;

$max-width: 1920px;

$fs: (
    "20": 16px,
    "30": 18px,
    "40": 20px,
    "50": 32px,
    "60": 36px,
    "70": 46px,
    "80": 64px,
);

$breakpoints: (
    "x-small": 800px,
    "small": 1200px,
    "medium": 1400px,
);
