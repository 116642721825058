@import "../mixins/mixins";
@import "../variables.scss";

.block {
    display: grid;
    grid-template-columns: 120px 1fr 120px;

    max-width: $max-width;
    margin: 0 auto;

    > * { grid-column: 2 / 3; }

    &.fullbleed { 
        > * { grid-column: 1 / 4; }
    }

    @include respondTo("small") {
        grid-template-columns: 30px 1fr 30px;
    }
}
