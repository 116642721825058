@import "../variables.scss";
@import "../mixins/mixins";

.hamburger {
    position: relative;
    
    width: 40px;
    height: 40px;

    background: none;
    border: none;

    cursor: pointer;

    span {
        position: absolute;

        width: 30px;
        height: 2px;

        background-color: $surface-on;
    }
}