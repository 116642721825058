@import "../mixins/_mixins.scss";
@import "../variables.scss";

.service {
    padding: 16px;

    background-color: $surface-dark;
    border-radius: 16px;
    
    .overlap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .info_container {
            display: grid;
            gap: 8px;

            align-self: start;
            justify-self: start;

            h6 {
                font-size: map-get($fs, "30");
            }
                
            span { 
                font-size: map-get($fs, "20");
                opacity: 0.5;
            }
        }

        .book_container {
            display: grid;
            gap: 8px;

            align-self: center;
            justify-self: end;

            width: 125px;

            span { justify-self: center; }

            button {
                width: 100%;
            }
        }
    }

    @include respondTo("small") {
        .overlap {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            gap: 16px;

            .book_container {
                width: 100%;

                span { justify-self: start; }

                a {
                    display: block;
                    width: 100%;
                }
            }
        }
	}
}

