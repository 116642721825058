@import "../variables.scss";
@import "../mixins/mixins";


.header {
    position: sticky;
    top: 0;

    padding-block: 8px;

    width: 100%;

    background-color: $surface;

    border-bottom: 4px solid $surface-light;

    z-index: 99999;
    
    .overlap {
        display: grid;
        grid-template-areas: "image menu";

        .img_container {
            grid-area: image;

            justify-self: start;
            align-self: center;

            width: 150px;
            height: 50px;

            @include respondTo("small") {
                width: 70px;
                height: 25px;
            }
        }
    
        > :nth-child(2) {
            grid-area: menu;
            justify-self: end;
        }
    }


}


